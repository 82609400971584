<template>
  <div class="home_tab_container">
    <div class="home_tab warp" data-aos="fade-down" data-aos-delay="50">
      <div
        :class="
          `${
            tabValue === index
              ? 'home_tab_item_active home_tab_item'
              : 'home_tab_item'
          }`
        "
        v-for="(item, index) in tabList"
        :key="item.title"
        @click="changeTab(index)"
      >
        <div>
          <img class="home_tab_item_icon" :src="item.icon" />
        </div>
        <div class="home_tab_item_title">{{ item.title }}</div>
        <div class="home_tab_item_subtitle">{{ item.subTitle }}</div>
      </div>
    </div>
    <TabOne v-if="tabValue === 0" />
    <TabTwo v-if="tabValue === 1" />
    <TabThree v-if="tabValue === 2" />
    <TabFour v-if="tabValue === 3" />
    <TabFive v-if="tabValue === 4" />
    <div class="home_tab_two_faq" data-aos="fade-down" data-aos-delay="150">
      <div class="warp">
        <div class="tab_title">外贸极客如何解决外贸人的营销需求和问题？</div>
        <div class="faq_container">
          <div
            :class="`${item.className} faq_item`"
            v-for="item in faqList"
            :key="item.title"
          >
            <div class="faq_item_title">
              <img :src="item.icon" />
              <div>{{ item.title }}</div>
            </div>
            <div class="faq_item_subtitle">{{ item.subTitle }}</div>
          </div>
        </div>
        <div class="faq_tip">
          AB客倾力打造为外贸人打造的最佳智能营销伙伴<img
            src="@/assets/home/icon/scrmAI.png"
          />
        </div>
        <div class="faq_tip">
          让您的营销更加简单高效，且不断增强业务竞争力，实现业绩持续快速增长!
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import icon1 from "@/assets/home/icon/智能自动化.png";
import icon2 from "@/assets/home/icon/专业且高效.png";
import icon3 from "@/assets/home/icon/超高性价比.png";
import icon4 from "@/assets/home/icon/低使用门槛.png";
import icon5 from "@/assets/home/icon/超值大优惠.png";
import icon6 from "@/assets/home/icon/客户订单.png";
import icon7 from "@/assets/home/icon/性价比.png";
import icon8 from "@/assets/home/icon/专业知识.png";
import TabOne from "./TabOne.vue";
import TabTwo from "./TabTwo.vue";
import TabThree from "./TabThree.vue";
import TabFour from "./TabFour.vue";
import TabFive from "./TabFive.vue";

export default {
  components: {
    TabOne,
    TabTwo,
    TabThree,
    TabFour,
    TabFive,
  },
  data() {
    return {
      tabValue: 0,
      tabList: [
        {
          icon: icon1,
          title: "智能营销突破传统桎梏",
          subTitle: "智能自动化",
        },
        {
          icon: icon2,
          title: "客户和产品营销双驱动",
          subTitle: "专业且高效",
        },
        {
          icon: icon3,
          title: "洞察目标市场和受众",
          subTitle: "超高性价比",
        },
        {
          icon: icon4,
          title: "降低业务经验知识门槛",
          subTitle: "低使用门槛",
        },
        {
          icon: icon5,
          title: "享受专业优质服务",
          subTitle: "超值大优惠",
        },
      ],
      faqList: [
        {
          icon: icon6,
          title: "想要更多的客户订单？",
          subTitle:
            "市场定位、独立站与SEO优化、内容营销、多语种支持、进行社媒推广、参加贸易展会、客户管理和跟进、优质的售后服务、品牌影响力、口碑、专业人才和工具",
          className: "faq_item1",
        },
        {
          icon: icon7,
          title: "希望营销投入更具性价比？",
          subTitle:
            "营销自动化、目标客户识别与个性化营销、自动生成优质营销内容、智能SEO优化、智能客服支持、智能市场洞察、智能营销策略、数据分析",
          className: "faq_item2",
        },
        {
          icon: icon8,
          title: "获取专业知识经验更容易？",
          subTitle:
            "智能对话提供即时的专业知识和购销建议、多信息数据源整合分析处理、智能绘画提供大量营销图片需要、智能场景应用解决业务难题",
          className: "faq_item3",
        },
      ],
    };
  },
  methods: {
    changeTab(e) {
      this.tabValue = e;
    },
  },
};
</script>

<style lang="sass" scoped>
.home_tab_container {
  background: #F7FAFF;
  font-family: "Alibaba-PuHuiTi-R";
  padding-top: 20px;
  padding-bottom: 100px;
  .home_tab {
    display: flex;
.home_tab_item {
  flex: 1;
  cursor: pointer;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  padding: 27px 0;
  .home_tab_item_icon {
    width: 40px;
    height: 40px;
    }
      .home_tab_item_title {
        font-family: AlibabaPuHuiTiM;
        margin: 16px 0 8px 0;
        font-weight: 500;
font-size: 16px;
color: #222222;
        }
          .home_tab_item_subtitle {
            font-size: 14px;
color: #646A73;
            }
  }

      .home_tab_item_active {
      background: rgba(6,128,242,0.05);
      }
    }
    .home_tab_two_faq {
            background: #FFFFFF;
            padding: 100px 0 50px 0;
            .tab_title {
    margin-bottom: 54px;
    font-weight: 500;
    font-family: AlibabaPuHuiTiM;
font-size: 36px;
color: #111111;
text-align: center;
    }
            .faq_tip {
              font-size: 18px;
color: #111111;
justify-content: center;
margin-bottom: 10px;
display: flex;
align-items: center;
column-gap: 8px;
img {
  width: 80px;
  }
              }
            .faq_container {
              display: flex;
              column-gap: 18px;
              margin-bottom: 30px;
              .faq_item {
                flex: 1;
                padding: 44px 34px 54px 34px;
                background: linear-gradient(#F3F1FF 0%, #FDFCFF 100%);
border-radius: 10px;
                .faq_item_title {
                  display: flex;
              column-gap: 10px;
              align-items: center;
              font-weight: 500;
              font-family: AlibabaPuHuiTiM;
              margin-bottom: 18px;
font-size: 20px;
color: #111111;
              img {
                width: 40px;}
                  }
                    .faq_item_subtitle {
                      font-size: 16px;
color: #646A73;
line-height: 32px;
                      }
                }
                  .faq_item2 {
                    background: linear-gradient(#EEF2FF 0%, #F8FAFF 100%);
                    }
                    .faq_item3 {
                    background: linear-gradient(#E9F3FF 0%, #F9FBFD 100%);
                    }
              }
            }
  }
</style>
