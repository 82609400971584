<template>
  <div class="footerbg">
    <div class="warp">
      <div class="home-footer flex-col">
        <div class="footer-nav flex-row">
          <div class="company-info">
            <ul class="nav-list">
              <li>
                <!-- <div class="topic"> -->
                <img
                  class="logo"
                  referrerpolicy="no-referrer"
                  src="../assets/logo_白.png"
                />
                <!-- </div> -->
              </li>
              <li>
                <span class="item-words">咨询热线：400-076-6558</span>
              </li>
              <li class="flex-row">
                <span class="item-words fontSize16">地址：</span>
                <div class="flex-col">
                  <span class="item-words fontSize16"
                    >上海市嘉定区运通星财富广场B座20层</span
                  >
                  <span class="item-words fontSize16"
                    >郑州市高新区莲花街55号威科姆大厦A座10楼</span
                  >
                </div>
              </li>
              <li class="footer_ico">
                <a-popover placement="top">
                  <template slot="content">
                    <p>
                      <img
                        referrerpolicy="no-referrer"
                        src="~@/assets/images/wx.jpg"
                      />
                    </p>
                  </template>
                  <i>
                    <img
                      referrerpolicy="no-referrer"
                      src="~@/assets/images/wx.png"
                    />
                  </i>
                </a-popover>
                <a-popover placement="top">
                  <template slot="content">
                    <p>
                      <img
                        referrerpolicy="no-referrer"
                        src="~@/assets/images/app.png"
                      />
                    </p>
                  </template>
                  <i>
                    <img
                      style="width: 60%;"
                      referrerpolicy="no-referrer"
                      src="~@/assets/images/btn_download_n.png"
                    />
                  </i>
                </a-popover>
                <!-- <a-popover placement="top">
              <template slot="content">
                <p>
                  <img referrerpolicy="no-referrer" src="~@/assets/images/wx.jpg" />
                </p>
              </template>
              <i class="iconfont icon-douyin"></i>
            </a-popover>

            <i class="iconfont icon-zhihu"></i> -->
              </li>
            </ul>
          </div>

          <div class="align-start production-info1">
            <ul class="nav-list">
              <li>
                <div class="topic">产品服务</div>
              </li>
              <li>
                <router-link to="/product/igeek" class="item"
                  >外贸极客</router-link
                >
              </li>
              <!--<li>-->
              <!--<router-link to="/product/iexpress" class="item">询盘速递</router-link>-->
              <!--</li>-->
              <!--<li>-->
              <!--<router-link to="/product/iserver" class="item">询盘服务</router-link>-->
              <!--</li>-->
              <!--<li>-->
              <!--<router-link to="/product/brand" class="item">品牌营销</router-link>-->
              <!--</li>-->
              <li>
                <router-link to="/product/customs" class="item"
                  >海关数据</router-link
                >
              </li>
              <li>
                <router-link to="/product/intelligent-search" class="item"
                  >客户智搜</router-link
                >
              </li>
              <li>
                <router-link to="/product/search-customer" class="item"
                  >搜邮寻客</router-link
                >
              </li>
              <li>
                <router-link to="/product/customer-recommend" class="item"
                  >客户推荐</router-link
                >
              </li>
            </ul>
            <ul class="nav-list col-gutter">
              <li>
                <div class="topic"></div>
              </li>
              <li>
                <router-link to="/product/scrm" class="item"
                  >客户管理</router-link
                >
              </li>
              <li>
                <router-link to="/product/mail" class="item"
                  >智能营销</router-link
                >
              </li>
              <li>
                <router-link to="/product/mail" class="item"
                  >营销邮件</router-link
                >
              </li>
              <li>
                <router-link to="/product/global" class="item"
                  >全球电话</router-link
                >
              </li>
              <li>
                <router-link to="/product/im" class="item"
                  >即时通讯</router-link
                >
              </li>
            </ul>
          </div>
          <div class="justify-between" style="flex-grow: 1">
            <!-- <ul class="nav-list">
          <li>
            <div class="topic">解决方案</div>
          </li>
          <li>
            <router-link to="/" class="item">综合零售</router-link>
          </li>
          <li>
            <router-link to="/" class="item">跨境电商</router-link>
          </li>
          <li>
            <router-link to="/" class="item">消费电子</router-link>
          </li>
          <li>
            <router-link to="/" class="item">医疗健康</router-link>
          </li>
          <li>
            <router-link to="/" class="item">车辆配件</router-link>
          </li>
        </ul> -->
            <ul class="nav-list">
              <li>
                <div class="topic">外贸学院</div>
              </li>
              <li>
                <router-link to="/school/latest" class="item"
                  >最新资讯</router-link
                >
              </li>
              <li>
                <router-link to="/school/industry" class="item"
                  >行业洞察</router-link
                >
              </li>
              <li>
                <router-link to="/school/market" class="item"
                  >营销干货</router-link
                >
              </li>
              <li>
                <router-link to="/school/product-news" class="item"
                  >产品动态</router-link
                >
              </li>
              <li>
                <router-link to="/school/classroom" class="item"
                  >微课堂</router-link
                >
              </li>
              <li>
                <!-- <router-link to="/" class="item">大咖直播</router-link> -->
              </li>
            </ul>
            <ul class="nav-list">
              <li>
                <div class="topic">资源中心</div>
              </li>
              <li>
                <router-link to="/resource/help" class="item"
                  >帮助中心</router-link
                >
              </li>
              <li>
                <router-link to="/resource/navigation" class="item"
                  >资源导航</router-link
                >
              </li>
              <li>
                <router-link to="/resource/template" class="item"
                  >网站模板</router-link
                >
              </li>
              <!-- <li>
            <router-link to="/" class="item">下载中心</router-link>
          </li>
          <li>
            <router-link to="/" class="item">课程中心</router-link>
          </li> -->
            </ul>
            <ul class="nav-list">
              <li>
                <div class="topic">关于我们</div>
              </li>
              <li>
                <router-link to="/about" class="item">公司介绍</router-link>
              </li>
              <li>
                <router-link to="/about" class="item">企业新闻</router-link>
              </li>
              <li>
                <router-link to="/about" class="item">联系我们</router-link>
              </li>
              <li>
                <router-link to="/cooperation" class="item"
                  >渠道合作</router-link
                >
              </li>
            </ul>
          </div>
        </div>
        <div class="foot-bottom flex-row">
          <div class="icp">
            <span class="word"
              >Copyright&nbsp;©&nbsp;2022&nbsp;牧客科技&nbsp;&nbsp;
              <a
                href="https://www.cnabke.com/terms_of_service.html"
                target="_blank"
                >服务条款</a
              >
              <a
                href="https://www.cnabke.com/privacy_policy.html"
                target="_blank"
                >隐私政策</a
              >
              <a
                href="https://beian.miit.gov.cn/#/Integrated/index"
                target="_blank"
                >沪ICP备18032239号-2</a
              >
            </span>
          </div>
          <a
            href="https://xyt.xcc.cn/getpcInfo?sn=1567691173160509440&language=EN&certType=8&url=*.cnabke.com"
            target="_blank"
            style="position: relative;display: inline-block;height: 33px;margin: 0px 10px"
          >
            <div
              style="width:100%;height:100%;position: absolute;top: 0;left: 0;"
            ></div>
            <embed
              src="https://program.xinchacha.com/web/1567691173160509440=*.cnabke.comen.svg"
              width="103"
              height="38"
              type="image/svg+xml"
              pluginspage="//www.adobe.com/svg/viewer/install/"
          /></a>
          <div class="system-list flex-row justify-between">
            <span class="word"
              ><a
                @click="showModal"
                data-source="footer"
                onmouseover="linkDataSource(this)"
                a-type="register"
                target="_blank"
                >CRM客户管理</a
              ></span
            >
            <span class="word"
              ><a href="http://www.shl56.com/" target="_blank"
                >国际航运</a
              ></span
            >
            <span class="word"
              ><a href="https://www.goodcang.com/" target="_blank"
                >海外仓</a
              ></span
            >
            <span class="word"
              ><a href="http://www.sh-sgl.com/" target="_blank"
                >国际物流货代</a
              ></span
            >
            <span class="word"
              ><a
                href="https://sungreen.100jit.com/marketing-portal-front/"
                target="_blank"
                >海运订舱</a
              ></span
            >
            <span class="word"
              ><a href="https://www.chinatradenews.com.cn" target="_blank"
                >中国贸易新闻网</a
              ></span
            >
            <!-- <span class="word"
              ><a href="http://shippingchina.com/" target="_blank">国际海运网</a></span
            >
            <span class="word"
              ><a href="https://www.cantonfair.org.cn/" target="_blank"
                >进出口商品交易会</a
              ></span
            > -->
          </div>
          <div class="address-map">
            <a href="/about">
              <a-button type="primary">
                <i class="iconfont icon-31dingwei"></i> 上海
              </a-button></a
            >
          </div>
        </div>

        <div class="kf_service" data-aos="fade-left">
          <em
            ><a href="javascript:" onclick="open53()"
              ><img src="~@/assets/home/侧边悬浮_旋转头像.png" alt=""/></a
          ></em>
          <i><img src="~@/assets/home/image_客服.png" alt=""/></i>
          <strong><img src="~@/assets/home/侧边悬浮_客服1.png" alt=""/></strong>
          <span
            ><p class="iconfont icon-dianhuatianchong"></p>
            服务热线</span
          >
          <b> 400-076-6558 </b>
          <a
            @click="showModal"
            data-source="kf"
            onmouseover="linkDataSource(this)"
            a-type="register"
            target="_blank"
            rel="noopener noreferrer"
            >免费试用</a
          >
        </div>

        <a-back-top>
          <div class="ant-back-top-inner" data-aos="fade-up">
            <i class="iconfont icon-tubiao02"></i>
          </div>
        </a-back-top>
      </div>
    </div>
    <inquiry-modal-vue
      :visible="visible"
      @cancel="handleCancel"
    ></inquiry-modal-vue>
  </div>
</template>

<script>
import UserActiveLog from "@/utils/userActiveLog";
import inquiryModalVue from "@/components/InquiryModal2.vue";
export default {
  name: "Footer",
  components: {
    inquiryModalVue,
  },
  data() {
    return {
      visible: false,
    };
  },
  mounted() {
    console.log("UserActiveLogUserActiveLogUserActiveLog");
    // userLog.onBehaviorUpdate();
    const userLog = Object.create(UserActiveLog);
    userLog.constructor();
    userLog.initFingerprintJS();
    userLog.onBehaviorUpdate();
    userLog.urlCache();
    // let _hmt = _hmt || [];
    // let hm = document.createElement('script');
    // hm.src = 'https://hm.baidu.com/hm.js?d7a429c980125f29686e36e37c5a2be2';
    // let s = document.getElementsByTagName('script')[0];
    // s.parentNode.insertBefore(hm, s);
  },
  methods: {
    async activeBehaviorUpdate(to, from) {
      // console.log("to, from");
      console.log(to, from);
      console.log("route activeBehaviorUpdate");
      const userLog = Object.create(UserActiveLog);
      userLog.onBehaviorUpdate();
      userLog.constructor();
      await userLog.initFingerprintJS();
      userLog.urlCache();
      if (from && from.path !== undefined) {
        let urlPath = from.path;
        urlPath = urlPath.replace(/^\//, "");
        urlPath = urlPath.replaceAll("/", "_");
        userLog.setUserBehaviorLogField({
          sourcePageCode: urlPath,
          sourcePageName: urlPath,
        });
      }
    },
    showModal() {
      window.open("https://crm.cnabke.com/#/passport/register", "_blank");
      this.visible = true;
    },

    handleCancel() {
      this.visible = false;
    },
  },
  watch: {
    $route: "activeBehaviorUpdate",
  },
};
// scoped
</script>
<style lang="sass" scoped>
.footerbg{
  background-color: #0d0f1c;
  z-index: 999;
  position: relative;
}
.footer_ico{
  i{
  height:46px;
  width:46px;
  border: 1px solid #282d44;
  display: inline-block;
  text-align: center;
  line-height: 40px;
  border-radius: 50%;
  cursor: pointer;
  margin-right:10px;
  margin-top:15px;
      font-size: 25px;
  }
  i.icon-zhihu,i.icon-douyin{
    line-height: 44px;
  }

}
.footer_ico i:hover{
  background:#358af7;
}
.ant-back-top{
  bottom:120px;
}
.ant-back-top-inner{
  background:#fff;
  height:64px;
  width:64px;
  border-radius: 50%;
  text-align: center;
  line-height: 64px;
  box-shadow: 0px 4px 10px 0px rgba(0,0,0,0.2000);
  margin-left: 41px;
  i{
  color:#dadada;
      font-size: 28px
  }
}

.ant-back-top-inner:hover i{
color:#1890ff
}
@keyframes zq{ from{transform:rotate(0deg);}
to{transform:rotate(360deg);}
}

@-webkit-keyframes zq{ from{-webkit-transform:rotate(0deg);}
to{-webkit-transform:rotate(360deg);}
}

.kf_service{
  text-align: center;
  position: fixed;z-index: 999;
  background:#fff;
  bottom:30%;
  right: 10px;
 box-shadow: 0px 4px 10px 0px rgba(0,0,0,0.2000);
  width: 120px;
  height:240px;
   border-top-left-radius: 100px;
   border-top-right-radius: 100px;
   border-bottom-right-radius: 6px;
   border-bottom-left-radius: 6px;
   i{
    z-index: 11;
    position: relative;
 margin-bottom: 15px;
    display: block;
    img{
    border-radius:50%;
    margin-top: 20px;
    width: 87px;
    height: 87px;
    overflow: hidden
   }
   }
   strong{
     position: absolute;
     left: 15px;
     top: 18px;
    img{
          -webkit-animation: zq 1s infinite;
          animation: zq 1s infinite;
          width: 91px;
          overflow: hidden;
          height: 91px;
          border-radius: 50%;

        }
   }
   b{
    padding-top:10px;
    display:block;
    padding-bottom:18px;
        font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
        color:#333;
   }
em{
  a{
    &:hover{
      background:none;
      opacity: .1;
    }
    background:none;
  }
    position: absolute;
    top: 14px;
    left: 11px;  z-index: 12;
    img{
    width:94px
    }


}
span{
      display:block;
      p{
        margin-right:5px;
        margin-bottom:0px;
        color:#1C84FD;
        display: inline-block;
      }
}
a{
display:block;
 width: 63px;
font-size: 16px;
color: #FFFFFF;
line-height: 34px;
width: 90px;
height: 34px;
background: linear-gradient(230deg, #198BFF 0%, #217AF9 100%);
border-radius: 4px;
margin: 0px auto;
&:hover{
  background:#095bd1
}
}

}
  .home-footer {
    background-color: rgba(13, 15, 28, 1);
    width: 100%;
    padding: 51px 0px 0 0px;
    min-width: 1180px;
    .footer-nav {
      margin: 0 auto;
      width: 100%;
      overflow: hidden;
      height:386px;
      border-bottom: 1px solid rgba(216, 216, 216, 0.1);

      .word-base {
        overflow-wrap: break-word;
        color: rgba(255, 255, 255, 1);
        font-family: Alibaba-PuHuiTi-R;
        text-align: left;
        white-space: nowrap;
        display: block;
      }

      ul{
        padding-left: 0;
        margin: 0;
      }

      .company-info{
        width: 624px;
      }

      .production-info1{
        width: 11.44rem;
      }

      .col-gutter{
        margin-left: 100px;
      }

      .nav-list {
        list-style: none;
        // width: 85px;
        color: #ffffff;
        float: left;
        text-align: left;
        a {
          text-decoration: none;

        }
        .logo {
          width: 100px;
          height: 88px;
          margin-left: 9px;
          margin-bottom: 40px;
        }
        .company-entry{
          width:257px;
          // height:100%;
          padding-top:20px;
        }
        .topic{
          // width: 85px;
          height: 27px;
          font-size: 20px;
          font-family: AlibabaPuHuiTiM;
          font-weight: normal;
          color: #FFFFFF;
          line-height: 27px;
          letter-spacing: 1px;
          margin-bottom: 20px;
        }
        .item {
          width: 67px;
          font-size: 16px;
          font-family: Alibaba-PuHuiTi-R;
          font-weight: normal;
          color: #FFFFFF;
          line-height: 34px;
          letter-spacing: 1px;
        }
        a.item{
        color:rgba(255,255,255,.5)
        }
        a.item:hover{
        color:#fff;
        }
      }
    }
    .item-words{
      font-family: Alibaba-PuHuiTi-R;
      font-weight: normal;
      color: #FFFFFF;
      line-height: 34px;
      letter-spacing: 1px;
    }

    .foot-bottom{
      height: 75px;
      align-items: center;
      // padding-top: 32px;
      // padding-bottom: 21px;
      .icp{
        // width: 622px;
        // height: 22px;
      }

      .word{
        overflow-wrap: break-word;
        color:#fff;
        font-size: 16px;
        letter-spacing: 1px;
        font-family: Alibaba-PuHuiTi-R;
        text-align: left;
        white-space: nowrap;
        line-height: 22px;
        margin-top: 7px;
        display: block;
        a{
        color:#fff
        }
        a:hover{
      color:#358af7
        }
      }

      .system-list{
        flex-grow: 1;
        padding-right: 106px;
      }
      .address-map{
      i{
      margin-right:3px;
      }

      }
    }
  }
</style>
