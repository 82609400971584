<template>
  <div class="ab-saas">
    <div class="ab-saas-title1" data-aos="fade">了解SaaS产品</div>
    <div class="ab-saas-production">
      <div class="ab-saas-title2" data-aos="fade" data-aos-delay="100">
        构建一站式外贸智能营销生态
      </div>
      <div class="ab-saas-items justify-between">
        <div
          class="item-block1"
          data-aos="fade"
          data-aos-delay="0"
          data-aos-easing="ease-in-out"
        >
          <div class="item-name flex-col justify-between">
            <span class="cn-name">智能营销</span>
            <span class="en-name">Intelligent marketing</span>
          </div>
          <div class="item-content flex-col">
            <div class="item-detail flex-row">
              <div class="iconfont icon-wangzhanjiance flex-col"></div>
              <div class="item-text-block flex-col justify-between">
                <span class="item-title fontsize20"
                  ><router-link to="/product/igeek"
                    >AI一键生成营销内容</router-link
                  ></span
                >
                <span class="item-desc fontsize14">
                  <router-link to="/product/igeek">
                    高质量多语种营销内容一键生成，内容管理智能化，品牌传播力倍增。</router-link
                  >
                </span>
              </div>
            </div>
            <div class="item-detail flex-row">
              <div class="iconfont icon-a-Frame36324 flex-col"></div>
              <div class="item-text-block flex-col justify-between">
                <span class="item-title fontsize20">
                  <router-link to="/product/igeek"> 智能助手</router-link>
                </span>
                <span class="item-desc fontsize14">
                  <router-link to="/product/igeek">
                    专业智能助理、助力专业营销管理智能体服务
                    简化指令，高效完成各项营销任务</router-link
                  >
                </span>
              </div>
            </div>
            <div class="item-detail flex-row">
              <div class="iconfont icon-yingxiaoyunwei flex-col"></div>
              <div class="item-text-block flex-col justify-between">
                <span class="item-title fontsize20"
                  ><router-link to="/product/igeek">
                    营销智能体</router-link
                  ></span
                >
                <span class="item-desc fontsize14">
                  <router-link to="/product/igeek">
                    赋能营销
                    新维智启，全时在线人性化营销智能体，智能交互新体验</router-link
                  >
                </span>
              </div>
            </div>
          </div>
        </div>
        <div
          class="item-block2"
          data-aos="fade"
          data-aos-delay="200"
          data-aos-offset="100"
          data-aos-easing="ease-in-out"
        >
          <div class="item-name flex-col justify-between">
            <span class="cn-name">渠道获客</span>
            <span class="en-name">Channel customer acquisition</span>
          </div>
          <div class="item-content flex-col justify-between">
            <div class="item-detail flex-row">
              <div class="iconfont icon-a-Frame427320434 flex-col"></div>
              <div class="item-text-block flex-col justify-between">
                <span class="item-title fontsize20">
                  <router-link to="/product/igeek">AI建站推广</router-link>
                </span>
                <span class="item-desc fontsize14">
                  <router-link to="/product/igeek"
                    >智能专业自动化建站优化和推广，提升搜索排名
                    引爆品牌影响力！</router-link
                  >
                </span>
              </div>
            </div>
            <div class="item-detail flex-row">
              <div class="iconfont icon-facebook flex-col"></div>
              <div class="item-text-block flex-col justify-between">
                <span class="item-title fontsize20"
                  ><router-link to="/product/igeek"
                    >智能Facebook运营</router-link
                  ></span
                >
                <span class="item-desc fontsize14">
                  <router-link to="/product/igeek"
                    >在社交纷争中脱颖而出，与客户建立真正的连接。</router-link
                  >
                </span>
              </div>
            </div>
            <div class="item-detail flex-row">
              <div class="iconfont icon-linkedin flex-col"></div>
              <div class="item-text-block flex-col justify-between">
                <span class="item-title fontsize20">
                  <router-link to="/product/igeek"
                    >智能Linkedin运营</router-link
                  >
                </span>
                <span class="item-desc fontsize14">
                  <router-link to="/product/igeek"
                    >高效链接全球商业精英，开拓B2B市场新天地！</router-link
                  >
                </span>
              </div>
            </div>
            <div class="item-detail flex-row">
              <div class="iconfont icon-whatsapp2 flex-col"></div>
              <div class="item-text-block flex-col justify-between">
                <span class="item-title fontsize20">
                  <router-link to="/product/igeek"
                    >智能WhatsApp营销</router-link
                  >
                </span>
                <span class="item-desc fontsize14">
                  <router-link to="/product/igeek"
                    >直接触达客户，实时沟通，马上成交。</router-link
                  >
                </span>
              </div>
            </div>
            <div class="item-detail flex-row">
              <div class="iconfont icon-jiankong flex-col"></div>
              <div class="item-text-block flex-col justify-between">
                <span class="item-title fontsize20">
                  <router-link to="/product/igeek">商机监控</router-link>
                </span>
                <span class="item-desc fontsize14">
                  <router-link to="/product/igeek"
                    >商机实时探测器 — 揭秘网络淘金术！</router-link
                  >
                </span>
              </div>
            </div>
            <div class="item-detail flex-row">
              <div class="iconfont icon-icon-youzhikehutuijian flex-col"></div>
              <div class="item-text-block flex-col justify-between">
                <span class="item-title fontsize20">
                  <router-link to="/product/customer-recommend"
                    >客户推荐</router-link
                  >
                </span>
                <span class="item-desc fontsize14">
                  <router-link to="/product/customer-recommend"
                    >精准客户开发，手到擒来！</router-link
                  >
                </span>
              </div>
            </div>
          </div>
        </div>
        <div
          class="item-block3"
          data-aos="fade"
          data-aos-delay="300"
          data-aos-easing="ease-in-out"
        >
          <div class="item-name flex-col justify-between">
            <span class="cn-name">综合服务</span>
            <span class="en-name">Comprehensive services</span>
          </div>
          <div class="item-content flex-col justify-between">
            <div class="item-detail flex-row">
              <div class="iconfont icon-a-Frame36377 flex-col"></div>
              <div class="item-text-block flex-col justify-between">
                <span class="item-title fontsize20"
                  ><router-link to="/product/igeek">CRM</router-link></span
                >
                <span class="item-desc fontsize14">
                  <router-link to="/product/igeek">
                    高效管理客户关系网络 - 打造理想的客户沟通王国
                  </router-link>
                </span>
              </div>
            </div>
            <div class="item-detail flex-row">
              <div class="iconfont icon-youjian flex-col"></div>
              <div class="item-text-block flex-col justify-between">
                <span class="item-title fontsize20"
                  ><router-link to="/product/igeek"
                    >智能邮件营销</router-link
                  ></span
                >
                <span class="item-desc fontsize14">
                  <router-link to="/product/igeek">
                    助力B2B企业打造智能邮件营销新纪元
                  </router-link>
                </span>
              </div>
            </div>
            <div class="item-detail flex-row">
              <div class="iconfont icon-a-Frame427320432 flex-col"></div>
              <div class="item-text-block flex-col justify-between">
                <span class="item-title fontsize20"
                  ><router-link to="/product/igeek"
                    >数字化营销</router-link
                  ></span
                >
                <span class="item-desc fontsize14">
                  <router-link to="/product/igeek"
                    >全面数字化的营销业务，提供决策支持，策略优化，营销更精准。</router-link
                  >
                </span>
              </div>
            </div>
            <div class="item-detail flex-row">
              <div class="iconfont icon-fuwuzhichieps flex-col"></div>
              <div class="item-text-block flex-col justify-between">
                <span class="item-title fontsize20"
                  ><router-link to="/product/igeek">服务支持</router-link></span
                >
                <span class="item-desc fontsize14">
                  <router-link to="/product/igeek"
                    >与技术同行，为您提供绝佳服务体验</router-link
                  >
                </span>
              </div>
            </div>
            <div class="item-detail flex-row">
              <div class="iconfont icon-xinwenzixun flex-col"></div>
              <div class="item-text-block flex-col justify-between">
                <span class="item-title fontsize20"
                  ><router-link to="/product/igeek">行业资讯</router-link></span
                >
                <span class="item-desc fontsize14">
                  <router-link to="/product/igeek"
                    >第一时间掌握行业动向,专业知识和经验不再是门槛</router-link
                  >
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "abSaas",
};
</script>

<style lang="sass" scoped>
  $icons-block1: (
    "icon-xpsd": "icon_询盘速递",
    "icon-xpfu": "icon_询盘服务",
    "icon-ppyx": "icon_品牌营销",
    "icon-xpsc": "icon_询盘市场",
    "icon-xpjk": "icon_询盘监控"
  );
  $icons-block2: (
    "icon-hgsj": "icon_海关数据",
    "icon-khzs": "icon_客户智搜",
    "icon-syxk": "icon_搜邮寻客",
    "icon-sjwj": "icon_商机挖掘",
    "icon-khtj": "icon_客户推荐"
  );
  $icons-block3: (
    "icon-khgl": "icon_客户管理",
    "icon-znyx": "icon_智能营销",
    "icon-sjjc": "icon_数据决策",
    "icon-zhfw": "icon_综合服务",
    "icon-wmzx": "icon_外贸资讯"
  );
 .ab-saas .ab-saas-production .ab-saas-items .item-content .item-text-block:hover {
  cursor: pointer;
}
.ab-saas .ab-saas-production .ab-saas-items .item-content .item-text-block:hover  .item-title{
  color: #1890ff
}

  .ab-saas{
    background-color: rgba(247, 248, 252, 1);
    height: 960px;
    padding-top:60px;

    .ab-saas-title1{
      text-align: center;
      // width: 254px;
      height: 55px;
      font-size: 40px;
      font-family: AlibabaPuHuiTiB;
      color: #000000;
      line-height: 55px;
    }
    .ab-saas-title2{
      text-align: center;
      // width: 254px;
      // width: 256px;
      height: 27px;
      font-size: 20px;
      font-family: Alibaba-PuHuiTi-R;
      font-weight: normal;
      color: #000000;
      line-height: 27px;
      opacity: 0.4;
    }

    .ab-saas-production{
      margin-top: 10px;
      height: 900px;
      z-index: 545;
      // height: 845px;
      background: url('~@/assets/images/背景_了解SaaS产品.jpg') top center;

      // width: 1920px;
      // position: relative;
      background-size: cover;

      .ab-saas-items{
        margin:0px auto;
        margin-top: 50px;
        height: 700px;
        width: 1620px;

        .item-common{
          z-index: 549;
          height: 700px;
          overflow: hidden;
          // background-size: 520px 740px;
          background-size: cover;
          width: 480px;
          box-shadow: 0px 10px 20px 0px rgba(0,8,29,0.3000);
          border-radius: 6px;

          .item-name{
            margin-top: 31px;
            margin-left: 39px;
            .cn-name{
              width: auto;
              height: 44px;
              font-size: 32px;
              font-family: AlibabaPuHuiTiM;
              color: #FFFFFF;
              line-height: 44px;
            }
            .en-name{
              width: auto;
              height: 27px;
              font-size: 20px;
              font-family: AlibabaPuHuiTiM;
              color: #FFFFFF;
              line-height: 27px;
            }
          }

          .item-content{
            height: 557px;width: 435px;
            margin-top: 41px;
            padding: 25px 67px 31px 39px;
            background:#fff;
            border-radius: 6px;
            row-gap: 20px;
            .iconfont {
              color: #333646;
              opacity: 0.5;
              font-size:24px;
            }
            .item-title{
              height: 27px;
              font-family: AlibabaPuHuiTiM;
              color: #333646;
              line-height: 27px;
              text-align: left;

            }
            .item-desc{

              width: 300px;
              font-family: Alibaba-PuHuiTi-R;
              font-weight: normal;
              color: #333646;
              // line-height: 44px;
              text-align: left;
              opacity: 0.5;
              padding: 5px 0;
            }

            .item-text-block{
              margin-left: 16px;

            }
          }
        }

        .item-block1 {
          background: url('~@/assets/home/pic_11.jpg') top center no-repeat;
          @extend .item-common;
          background-size: cover;
        }

        .item-block2 {
          background: url('~@/assets/home/pic_12.jpg') top center no-repeat;
          background-size: cover;
          @extend .item-common;
        }

        .item-block3 {
          background: url('~@/assets/home/pic_13.jpg') top center no-repeat;
          background-size: cover;
          @extend .item-common;
        }

        .icon-style{
          width: 22px;
          height: 22px;
          margin-top: 4px;
        }
        // .icon-xpsd{
        //     background: url('~@/assets/home/icon_询盘速递.png') 100% no-repeat;
        // }

        .icon-xpfw{
            width: 22px;
            height: 22px;
            background: url('~@/assets/home/icon_询盘服务.png') 100% no-repeat;
            background-size: cover;
            margin-top: 4px;
        }

        @each $key, $value in $icons-block1 {
          .#{$key} {
            // content: $value;
            width: 22px;
            height: 22px;
            margin-top: 4px;
            background: url('~@/assets/home/' + $value + '.png') 100% no-repeat;background-size: cover;
          }
        }

        @each $key, $value in $icons-block2 {
          .#{$key} {
            // content: $value;
            width: 22px;
            height: 22px;
            margin-top: 4px;
            background: url('~@/assets/home/' + $value + '.png') 100% no-repeat;background-size: cover;
          }
        }

        @each $key, $value in $icons-block3 {
          .#{$key} {
            // content: $value;
            width: 22px;
            height: 22px;
            margin-top: 4px;
            background: url('~@/assets/home/' + $value + '.png') 100% no-repeat;background-size: cover;
          }
        }

      }
    }


  }
</style>
